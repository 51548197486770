import moment from "moment";
import "moment/locale/pt";
import React from "react";
import { useLocation } from "react-router-dom";
import BackButton from "../../resources/components/general/BackButton";
import Wrapper from "../../resources/components/general/Wrapper";
import Divider from "../../resources/images/home/page-divider.png";
import NewsHero from "../../resources/images/news/news_hero.png";
import { ReactComponent as LeafBG } from "../../resources/images/news/palm_bg.svg";
import { TextureBackground } from "./styles/news_style";

function ShowHighlights() {
  const { state } = useLocation();
  const item = state?.item;
  //const allNews = state?.news;

  function getParsedDate(dateString) {
    const date = moment(dateString);
    date.locale("pt");
    const formattedDate = date.format("DD MMM YYYY");

    return formattedDate;
  }

  //let randomItems = allNews?.sort(() => 0.5 - Math.random()).slice(0, 3);

  return (
    <>
      <div className="min-h-[450px] w-full relative overflow-hidden z-[1]">
        <img
          src={item?.photo_url ?? NewsHero}
          alt=""
          className="absolute object-cover w-full"
        />

        <Wrapper closeFull>
          <div className="flex flex-col h-full justify-between mt-[100px]">
            <BackButton className={"top-30 md:top-40 text-white"} />
          </div>
        </Wrapper>
      </div>

      <TextureBackground className="relative z-[1]">
        <img
          src={Divider}
          alt=""
          className="absolute top-[-100px] z-[-1] -rotate-180 w-full"
        />
        <Wrapper closeFull>
          <h2 className="font-semibold uppercase text-3xl w-10/12 text-black py-0 md:py-10">
            {item?.name}
          </h2>
          <div className="flex flex-col md:flex-row gap-20">
            <div className="basis-3/4">
              <div className="border-b border-b-[#D9D9D9] mb-3">
                <p>{item?.author}</p>
                <p className="mb-3">
                  {item?.published_date
                    ? getParsedDate(item?.published_date)
                    : getParsedDate(item?.created_at)}
                </p>
              </div>

              <p className="text-lg text-[#707070] text-justify whitespace-pre-line pb-20">
                {item?.description}
              </p>
            </div>
            {/*
            <div className="flex flex-col basis-2/6 overflow-auto">
              <h2 className="uppercase font-semibold mb-5">
                Notícias Relacionadas
              </h2>
              {console.log(allNews)}
              {randomItems &&
                randomItems.length > 0 &&
                randomItems.map((item, index) => {
                  return <NewCard data={item} news={allNews} key={index} />;
                })}
            </div>
            */}
          </div>
        </Wrapper>
        <LeafBG className="absolute z-[-1] bottom-0 right-0 opacity-40 origin-bottom-right scale-75" />
      </TextureBackground>
    </>
  );
}

export default ShowHighlights;
